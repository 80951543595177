import { VIcon } from 'vuetify/lib/components/VIcon';
import { VProgressCircular } from 'vuetify/lib/components/VProgressCircular';
import { VRow } from 'vuetify/lib/components/VGrid';
import { VSheet } from 'vuetify/lib/components/VSheet';
import { VSpacer } from 'vuetify/lib/components/VGrid';
import { VTooltip } from 'vuetify/lib/components/VTooltip';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c(VRow,{staticClass:"d-flex justify-center"},[(_vm.morningTimesPresent || _vm.afternoonTimesPresent)?_c('div',{staticClass:"d-flex flex-row",staticStyle:{"z-index":"6"}},[_c(VSheet,{staticClass:"d-flex flex-row justify-space-between align-center py-2 px-2",attrs:{"rounded":"pill","elevation":"3"}},[_c(VTooltip,{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c(VProgressCircular,_vm._g(_vm._b({staticClass:"d-flex align-center justify-center",attrs:{"rotate":-90,"size":40,"width":5,"value":_vm.dayTimeProgressObject.complete || (_vm.hasVariationProposal && !_vm.variationProposalCreating) ? 100 : _vm.dayTimeProgressObject.progressPercentage,"color":_vm.dayStatusObject.color}},'v-progress-circular',attrs,false),on),[_c(VIcon,{attrs:{"color":_vm.dayStatusObject.color},on:{"click":function($event){return _vm.dayStatusObject.action()}}},[_vm._v(" "+_vm._s(_vm.dayStatusObject.icon)+" ")])],1)]}}],null,false,2351394350)},_vm._l((_vm.dayStatusObject.notes),function(note,index){return _c('div',{key:index},[_vm._v(" "+_vm._s(note)+" ")])}),0),_c(VSpacer,{staticClass:"mx-1"}),_c('div',{staticClass:"font-weight-medium d-flex flex-column mx-2 justify-center"},[(_vm.morningTimesPresent)?_c('span',[_vm._v(_vm._s(_vm.formattedTime( _vm.workDayTimes.morningTimes.clockInTime))+" - "+_vm._s(_vm.formattedTime( _vm.workDayTimes.morningTimes.clockOutTime)))]):_vm._e(),(_vm.afternoonTimesPresent)?_c('span',[_vm._v(_vm._s(_vm.formattedTime( _vm.workDayTimes.afternoonTimes.clockInTime))+" - "+_vm._s(_vm.formattedTime( _vm.workDayTimes.afternoonTimes.clockOutTime)))]):_vm._e()])],1)],1):_vm._e()])}
var staticRenderFns = []

export { render, staticRenderFns }