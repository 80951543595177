<template>
        <v-row class="d-flex justify-center">
            <div class="d-flex flex-row" style="z-index: 6;" v-if="morningTimesPresent || afternoonTimesPresent">
                <v-sheet rounded="pill" class="d-flex flex-row justify-space-between align-center py-2 px-2" elevation="3">
                    <v-tooltip bottom>
                    <template v-slot:activator="{ on, attrs }">
                        <v-progress-circular
                        :rotate="-90"
                        :size="40"
                        :width="5"
                        :value="dayTimeProgressObject.complete || (hasVariationProposal && !variationProposalCreating) ? 100 : dayTimeProgressObject.progressPercentage"
                        :color="dayStatusObject.color"
                        class="d-flex align-center justify-center"
                        v-on="on"
                        v-bind="attrs"
                        >
                        <v-icon 
                            :color="dayStatusObject.color"
                            @click="dayStatusObject.action()"
                        >
                            {{dayStatusObject.icon}}
                        </v-icon>
                        
                        </v-progress-circular>
                    </template>
                    <div v-for="(note, index) in dayStatusObject.notes" :key="index">
                        {{note}}
                    </div>
                    
                
                    </v-tooltip>
                    
                    <v-spacer class="mx-1"/>
                    <div class="font-weight-medium d-flex flex-column mx-2 justify-center">
                        <span v-if="morningTimesPresent">{{formattedTime( workDayTimes.morningTimes.clockInTime)}}&nbsp;-&nbsp;{{formattedTime( workDayTimes.morningTimes.clockOutTime)}}</span>
                        <span v-if="afternoonTimesPresent">{{formattedTime( workDayTimes.afternoonTimes.clockInTime)}}&nbsp;-&nbsp;{{formattedTime( workDayTimes.afternoonTimes.clockOutTime)}}</span>
                    </div>
                </v-sheet>
            </div>
        </v-row>
</template>
<script>

import { DateTime } from 'luxon'

export default {
    data: function() {
        return {

        }
    },
    props: {
        userWorkDay: {
            type: Object,
            required: true
        },
        workDayTimes: {
            type: Object,
            requried: true
        },
        isoWorkDate: {
            type: String,
            requried: true
        },
        workDaySummary: {
            type: Object,
            required: true
        }
    },
    methods: {
        formattedTime: function(timeISO) {
            if(timeISO) {
                return DateTime.fromISO(timeISO).toLocaleString(DateTime.TIME_24_SIMPLE)
            } else {
                return null;
            }
        },
        formattedTimeExtended: function(timeISO) {
            if(timeISO) {
                return DateTime.fromISO(timeISO).toLocaleString(DateTime.DATETIME_MED)
            } else {
                return null;
            }
        },
        formattedDuration: function(minutes) {
            let otm = minutes;
            return (otm >= 60 ? Math.floor(otm/60) + ' ore ' : '') + (otm % 60 != 0 ? otm % 60 + ' minuti': '').trim();
        },
    },
    watch: {
        // userWorkDay: {
        //     handler(newValue, oldValue) {
        //         console.log('changed')
        //     },
        //     deep: true
        // },
        // workDayTimes: {
        //     handler(newValue, oldValue) {
        //         console.log('changed1')
        //     },
        //     deep: true
        // }
        // dayTimeProgressObject: {
        //     deep: true,
        //     handler: function(val) {
        //         this.$emit('day-time-progress-update', val);
        //     }
        // }
    },
   
    computed: {
        dayStatusObject: function() {
            if(this.hasVariationProposal) {
                return {
                    color: "orange lighten-1",
                    icon: "mdi-alert-decagram",
                    action: () => {this.$emit('show-variation-proposal')},
                    notes: [
                        "Proposta di Modifica in attesa di approvazione",
                        "Creata il: " + this.formattedTimeExtended(this.userWorkDay.variationProposal.creationTime)
                    ],
                }
            } else {
                return {
                    color: this.dayTimeProgressObject.complete ? 'green lighten-2' : 'grey darken-1',
                    icon: "mdi-clock",
                    notes: [
                        "Tempo di lavoro pianificato: " + this.formattedDuration(this.dayTimeProgressObject.scheduledTime),
                        "Tempo di lavoro registrato: " + this.formattedDuration(this.dayTimeProgressObject.registeredTime)
                    ],
                    action: () => {}
                }
            }
        },
        hasVariationProposal: function() {
            return ((this.userWorkDay || {}).variationProposal || {}).creationTime != null;
        },
        dayTimeProgressObject: function() {
            let percentage = Math.min(Math.floor(((this.workDaySummary.registeredMinutes + this.workDaySummary.absenceMinutes) / this.workDaySummary.scheduledMinutes) * 100), 100);
            return {
                progressPercentage: percentage,
                registeredTime: this.workDaySummary.registeredMinutes + this.workDaySummary.absenceMinutes,
                scheduledTime: this.workDaySummary.scheduledMinutes,
                complete: percentage == 100 || this.isSickDay,
            };
        },
        isSickDay: function() {
            return this.workDayRecords.find(record => record.recordType == 'SICK_RECORD')
        },
        workDayRecords: function() {
            return ((this.userWorkDay || {}).workDayRecords || []);
        },
        morningTimesPresent: function() {
            return !!((this.workDayTimes.morningTimes || {}).clockInTime) && !!((this.workDayTimes.morningTimes || {}).clockOutTime);
        },
        afternoonTimesPresent: function() {
            return !!((this.workDayTimes.afternoonTimes || {}).clockInTime) && !!((this.workDayTimes.afternoonTimes || {}).clockOutTime);
        },
    }
}
</script>